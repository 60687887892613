export class UnauthenticatedError extends Error {
  name = 'UnauthenticatedError'
  message = 'Failed to authenticate user. Please log in before retrying'
}

export const getErrorMessage = (error: unknown): string =>
  error instanceof Error ? error.message : String(error)

export const normalizeError = (error: unknown) =>
  error instanceof Error
    ? error
    : new Error((error as Error)?.message || (error as string))
