<script setup lang="ts">
import { computed, ref, watch } from 'vue'
import {
  Combobox,
  ComboboxButton,
  ComboboxInput,
  ComboboxOption,
  ComboboxOptions,
  TransitionRoot,
} from '@headlessui/vue'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/vue/24/outline'

import { CloseIcon } from '@slippy-io/ui'

export interface IComboboxOption {
  label: string
  value: any
}
export type AutocompleteVariant = 'sm' | 'base' | 'lg'

const props = withDefaults(
  defineProps<{
    options: IComboboxOption[]
    selected?: IComboboxOption
    variant?: AutocompleteVariant
    placeholder?: string
    disabled?: boolean
    clearable?: boolean
  }>(),
  {
    variant: 'base',
    placeholder: 'Start typing to search...',
    selected: undefined,
    disabled: false,
    clearable: false,
  },
)
const emit = defineEmits<{
  (e: 'change', value: IComboboxOption): void
  (e: 'clear'): void
}>()

// State
const selectedOption = ref<IComboboxOption | undefined>(props.selected)
const query = ref('')
const comboBtn = ref<HTMLButtonElement>()

// Getters
const filterOptions = (option: IComboboxOption, query: string) =>
  option.label.toLowerCase().includes(query.toLowerCase())
const filteredOptions = computed(() =>
  query.value === ''
    ? props.options
    : props.options.filter((option) => filterOptions(option, query.value)),
)
const toDisplayName = (option: unknown) => {
  if (
    typeof option === 'object' &&
    option &&
    'label' in option &&
    typeof option.label === 'string'
  )
    return option.label
  return ''
}

// Watchers
watch(
  () => props.selected,
  (selected) => {
    selectedOption.value = selected ?? undefined
  },
)
watch(selectedOption, (selected) => {
  if (!selected) return
  if (selected.value === props.selected?.value) return

  emit('change', selected)
})
</script>

<template>
  <Combobox
    v-model="selectedOption"
    nullable
    by="value">
    <div class="relative text-gray-700">
      <div
        class="relative h-full w-full cursor-pointer overflow-hidden rounded-md border bg-white text-left focus:outline-none focus-visible:ring-2 focus-visible:ring-white sm:text-sm">
        <ComboboxInput
          class="w-full border-none pr-10 leading-5 focus:ring-0"
          :class="{
            'py-2 pl-3 text-sm': variant === 'sm',
            'py-2.5 pl-4 text-base': variant === 'base',
            'py-3 pl-5 text-lg': variant === 'lg',
          }"
          :display-value="toDisplayName"
          :disabled="disabled"
          :placeholder="placeholder"
          @change="query = $event.target.value" />
        <ComboboxButton
          ref="comboBtn"
          class="absolute inset-y-0 right-0 flex cursor-pointer items-center pr-2"
          :class="clearable ? 'right-4' : 'right-0'"
          :disabled="disabled">
          <ChevronUpDownIcon
            class="h-5 w-5 text-gray-400"
            aria-hidden="true" />
        </ComboboxButton>
        <div
          v-if="clearable"
          class="absolute inset-y-0 right-0 flex cursor-pointer items-center pr-1.5"
          @click.prevent="emit('clear')">
          <CloseIcon
            class="h-4 w-4 text-danger"
            aria-hidden="true" />
        </div>
      </div>

      <TransitionRoot
        leave="transition ease-in duration-100"
        leave-from="opacity-100"
        leave-to="opacity-0"
        @after-leave="query = ''">
        <ComboboxOptions
          class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
          <div
            v-if="filterOptions.length === 0 && query !== ''"
            class="relative cursor-default select-none px-4 py-2 text-gray-700">
            Nothing found.
          </div>
          <ComboboxOption
            v-for="option in filteredOptions"
            :key="option.value"
            v-slot="{ selected: isSelected, active }"
            :value="option">
            <li
              class="relative cursor-default select-none py-2 pl-4 pr-10"
              :class="{
                'bg-light-blue-50 text-gray-700': active,
                'text-gray-700': !active,
              }">
              <span class="block truncate">
                {{ option.label }}
              </span>
              <span
                v-if="isSelected"
                class="absolute inset-y-0 right-0 flex items-center pr-3"
                :class="{ 'text-white': active, 'text-teal-600': !active }">
                <CheckIcon
                  class="h-5 w-5 stroke-primary stroke-2"
                  aria-hidden="true" />
              </span>
            </li>
          </ComboboxOption>
        </ComboboxOptions>
      </TransitionRoot>
    </div>
  </Combobox>
</template>
