export interface IAuth {
  displayName: string
  email: string
  company: string
  tandcs: string | null
}

export type AccountType = 'merchant' | '3pl' | 'admin'
export enum AccountTypes {
  merchant = 'merchant',
  logistics = '3pl',
  admin = 'admin',
}
export const AUTH_ACCOUNT_TYPES: string[] = [
  AccountTypes.merchant,
  AccountTypes.logistics,
]
export const isAccountType = (type: unknown): type is AccountType => {
  if (typeof type !== 'string') return false
  if (!AUTH_ACCOUNT_TYPES.includes(type)) return false
  return true
}

export interface IUserClaims {
  team: string
  isBeta: boolean
  type: AccountType
  parentTeams: string[]
  childrenTeams: string[]
}

export interface IRule {
  id: number
  label: string
  regex: RegExp
}
