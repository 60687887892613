export const toTitleCase = (str: string) =>
  str.length < 2 ? str.toUpperCase() : `${str[0].toUpperCase()}${str.slice(1)}`

export const snakeToTitleCase = (str: string) =>
  str.split('_').map(toTitleCase).join(' ')

export const camelToTitleCase = (str: string) =>
  str
    .split(/(?=[A-Z])/)
    .map(toTitleCase)
    .join(' ')

export const kebabToTitleCase = (str: string) =>
  str.split('-').map(toTitleCase).join(' ')

export const dotToTitleCase = (str: string) =>
  str.split('.').map(toTitleCase).join(' ')

export const obscureText = (text: string, visible = 4) => {
  const obscuredLength = text.length - visible > 15 ? 15 : text.length - visible
  return Array(obscuredLength).join('*') + text.slice(-visible, text.length)
}

export const prettifyString = (str: string) =>
  snakeToTitleCase(kebabToTitleCase(dotToTitleCase(str)))
