import type { IdTokenResult } from 'firebase/auth'

import { AUTH_EMAIL_REGEX } from './auth.constants.js'
import { isAccountType, type IUserClaims } from './auth.models.js'

export const isName = (name: string): boolean => {
  if (name.length <= 2) return false
  return true
}

export const isEmail = (email: string): boolean => {
  if (email.match(AUTH_EMAIL_REGEX) === null) return false
  return true
}

export const getTokenClaims = (token: IdTokenResult): IUserClaims => {
  const { team, isBeta, type, parentTeams, childrenTeams } = token.claims

  if (!(team && typeof team === 'string'))
    console.warn('Cannot parse team from token claims. Team claim is required')
  if (!isAccountType(type))
    console.warn(
      'Cannot parse type from token claims. Account type is required',
    )

  return {
    team,
    type,
    isBeta: !!isBeta,
    parentTeams: Array.isArray(parentTeams) ? parentTeams : [],
    childrenTeams: Array.isArray(childrenTeams) ? childrenTeams : [],
  }
}
