import type { FirebaseOptions } from '@firebase/app'
import { z } from 'zod'

import { Environment } from '@/modules/utils/index.js'

export type Config = {
  ENVIRONMENT: Environment
  PROJECT: string
  VERSION: string
  PACKAGE_NAME: string
  FIREBASE_CONFIG: FirebaseOptions
  WEB_URL: string
  API_BASE_URL: string
  ALGOLIA: {
    APP_ID: string
    SEARCH_KEY: string
  }
  SENTRY: {
    DSN: string
    RELEASE: string
  }
  PRINTNODE_CONFIG: {
    macDownloadUrl: string
    windowsDownloadUrl: string
  }
}

const configSchema = z
  .object({
    VITE_ENVIRONMENT: z.enum([
      Environment.local,
      Environment.development,
      Environment.production,
    ]),
    VITE_PROJECT_ID: z.string(),
    VITE_PACKAGE_NAME: z.string(),
    VITE_VERSION: z.string(),
    VITE_FIREBASE_CONFIG: z
      .string()
      .transform((value) => JSON.parse(value))
      .pipe(
        z.object({
          apiKey: z.string(),
          authDomain: z.string(),
          projectId: z.string(),
          storageBucket: z.string(),
          messagingSenderId: z.string(),
          appId: z.string(),
          measurementId: z.string(),
        }),
      ),
    VITE_WEB_URL: z.string(),
    VITE_API_BASE_URL: z.string(),
    VITE_ALGOLIA_APP_ID: z.string(),
    VITE_ALGOLIA_SEARCH_KEY: z.string(),
    VITE_SENTRY_DSN: z.string(),
    VITE_PRINTNODE_CONFIG: z
      .string()
      .transform((value) => JSON.parse(value))
      .pipe(
        z.object({
          macDownloadUrl: z.string(),
          windowsDownloadUrl: z.string(),
        }),
      ),
  })
  .transform(
    ({
      VITE_ALGOLIA_APP_ID,
      VITE_ALGOLIA_SEARCH_KEY,
      VITE_SENTRY_DSN,
      ...rest
    }) =>
      ({
        ...Object.entries(rest).reduce(
          (prev, [key, value]) => ({
            ...prev,
            [key.replace('VITE_', '')]: value,
          }),
          {},
        ),
        ALGOLIA: {
          APP_ID: VITE_ALGOLIA_APP_ID,
          SEARCH_KEY: VITE_ALGOLIA_SEARCH_KEY,
        },
        SENTRY: {
          DSN: VITE_SENTRY_DSN,
          RELEASE: `slippy-web@${rest.VITE_VERSION}`,
        },
      } as Config),
  )

export const config = configSchema.parse(import.meta.env)
