import { computed, ref } from 'vue'
import { defineStore } from 'pinia'
import { asyncComputed, useStorage, watchOnce } from '@vueuse/core'
import { useAuth } from '@vueuse/firebase/useAuth'

import { FirebaseService } from '@/services/firebase.service.js'
import { UsersApi } from '@/modules/users/users.api.js'

import { AUTH_STORE_ID } from './auth.constants.js'
import { getTokenClaims, isEmail, isName } from './auth.utils.js'

export const useAuthStore = defineStore(
  AUTH_STORE_ID,
  () => {
    // Config
    const firebase = new FirebaseService()

    // State
    const { isAuthenticated, user } = useAuth(firebase.auth)
    const isReady = ref<boolean>(false)

    const token = asyncComputed(
      () => user.value?.getIdTokenResult(true) ?? null,
    )
    const claims = computed(() =>
      token.value ? getTokenClaims(token.value) : null,
    )
    const teamId = computed(() => claims.value?.team ?? null)
    const userId = computed(() => user.value?.uid ?? null)
    const isBeta = computed(() => claims.value?.isBeta ?? false)
    // ? Authentication fields
    const displayName = useStorage<string>(`${AUTH_STORE_ID}-displayName`, '')
    const email = useStorage<string>(`${AUTH_STORE_ID}-email`, '')
    const company = useStorage<string>(`${AUTH_STORE_ID}-company`, '')
    const tandcs = useStorage<string | null>(`${AUTH_STORE_ID}-tandcs`, null)

    // Getters
    const accountType = computed<string | null>(
      () => claims.value?.type ?? null,
    )
    const authToken = computed<string>(() => token.value?.token as string)

    // - Validators
    const isDisplayNameValid = computed(() => isName(displayName.value))
    const isEmailValid = computed(() => isEmail(email.value))
    const isCompanyValid = computed(() => isName(company.value))
    const hasAcceptedTandCs = computed(() => !!tandcs.value)

    const isSignUpPropsValid = computed(
      () =>
        isDisplayNameValid.value &&
        isEmailValid.value &&
        isCompanyValid.value &&
        hasAcceptedTandCs.value,
    )

    // Actions
    const updateDisplayName = (val: string) => (displayName.value = val)
    const updateEmail = (val: string) => (email.value = val)
    const updateCompany = (val: string) => (company.value = val)
    const updateTandCs = (val: string | null) => {
      tandcs.value = val
    }

    const signUp = async () => {
      if (!isSignUpPropsValid.value) throw new Error('Invalid sign up props')

      if (!tandcs.value) throw new Error('T&Cs not accepted')

      await new UsersApi().createUser({
        displayName: displayName.value,
        email: email.value.toLowerCase().trim(),
        company: company.value,
        tandcs: tandcs.value,
      })
    }

    const signIn = async () => {
      if (!isEmailValid.value) throw new Error('Invalid sign in props')
      await new UsersApi().requestSignInEmail({
        email: email.value.toLowerCase().trim(),
      })
    }

    const checkIfUserExists = async () => {
      return true
      // const auth = getAuth()
      // try {
      //   // ? Should never succeed
      //   await signInWithEmailAndPassword(
      //     auth,
      //     email.value,
      //     'slippy_random_string',
      //   )
      //   return false
      // } catch (error: any) {
      //   if (!error.code || error.code !== 'auth/wrong-password') {
      //     return false
      //   }
      //   return true
      // }
    }

    const signInWithEmailLink = async (loginUrl: string): Promise<boolean> => {
      try {
        if (!email.value.length) return false
        if (!firebase.validateLoginUrl(loginUrl)) return false

        const user = await firebase.signInWithEmailLink(email.value, loginUrl)
        isReady.value = true

        // Identify user
        try {
          window.analytics.identify(user.uid, {
            name: user.displayName,
            email: user.email,
            emailVerified: user.emailVerified,
            ...getTokenClaims(await user.getIdTokenResult(true)),
          })
        } catch {
          // Ignore
        }

        return true
      } catch (error) {
        return false
      }
    }

    const signOut = async () => {
      await firebase.signOut()
    }

    // Watchers
    firebase.onAuth(() => (isReady.value = true))
    watchOnce(
      user,
      () => (isReady.value = true),
      // { immediate: true },
    )

    return {
      // State
      isReady,
      isAuthenticated,
      firebaseUser: user,
      token,
      claims,
      teamId,
      userId,

      // ? Authentication fields
      displayName,
      email,
      company,
      tandcs,

      // Getters
      isBeta,
      accountType,
      isEmailValid,
      isSignUpPropsValid,
      authToken,

      // Actions
      updateDisplayName,
      updateEmail,
      updateCompany,
      updateTandCs,
      checkIfUserExists,
      signUp,
      signIn,
      signInWithEmailLink,
      signOut,
    }
  },
  { persist: true },
)
