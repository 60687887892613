import { computed, ref } from 'vue'
import { defineStore, storeToRefs } from 'pinia'
import { useFirestore } from '@vueuse/firebase/useFirestore'
import { orderBy, query, where } from '@firebase/firestore'

import { useUserStore } from '@/modules/users/index.js'

import { TEAM_STORE_ID } from './teams.constants.js'
import { Team, TeamTypes } from './teams.models.js'

export const useTeamStore = defineStore(
  TEAM_STORE_ID,
  () => {
    // Config
    const { user } = storeToRefs(useUserStore())

    // State
    const teamId = computed(() => user.value?.team)
    const isLoading = ref(false)

    // - Team Documents
    const teamDoc = computed(
      () => !!user.value?.team && Team.document(user.value.team),
    )

    /**
     * The users root team
     */
    const team = useFirestore(teamDoc, undefined, {
      errorHandler: (err) => {
        console.debug(`Error loading Team<${user.value?.team}>`, err)
      },
    })

    const isAdmin = computed(
      () => team.value && team.value.type === TeamTypes.admin,
    )

    const teamsQuery = computed(() => {
      if (!user.value?.team) return false
      if (isAdmin.value) return query(Team.collection, orderBy('name', 'asc'))
      return query(
        Team.collection,
        where('parents', 'array-contains', user.value.team),
      )
    })

    /**
     * All children teams
     */
    const accessibleTeams = useFirestore(teamsQuery, undefined, {
      errorHandler: (err) => {
        console.debug('Teams query failed', err)
      },
    })

    const teams = computed<Team[] | null>(() =>
      team.value && accessibleTeams.value
        ? [
            team.value,
            ...accessibleTeams.value.filter(
              (team) => team.id !== user.value?.team,
            ),
          ]
        : null,
    )
    const fallbackTeamId = computed(
      () => user.value?.activeTeam ?? user.value?.team,
    )

    // Getters
    const isReady = computed(
      () => !!team.value && teams.value && teams.value.length > 0,
    )

    const teamOptions = computed<{ label: Team['name']; value: Team['id'] }[]>(
      () => {
        if (!teams.value) return []
        return teams.value.map((team) => ({
          label: team?.name,
          value: team?.id,
        }))
      },
    )

    const teamType = computed<TeamTypes | undefined>(() => team.value?.type)
    const canSwitchTeams = computed(
      () =>
        !!team.value &&
        !!teams.value &&
        !!(teams.value.length > 1 || isAdmin.value),
    )

    const areAllowedTeamsReady = computed(
      () => !canSwitchTeams.value || (!!teams.value && teams.value.length > 1),
    )

    const getTeamById = (id: string | null) =>
      id ? teams.value?.find((team) => team.id === id) : undefined

    return {
      // State
      teamId,
      team,
      teams,
      isLoading,
      fallbackTeam: fallbackTeamId,

      // Getters
      isReady,
      isAdmin,
      areAllowedTeamsReady,
      teamOptions,
      teamType,
      canSwitchTeams,
      getTeamById,
    }
  },
  {
    persist: true,
  },
)
