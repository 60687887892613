import { computed, ref, watch } from 'vue'
import { defineStore, storeToRefs } from 'pinia'
import { useFirestore } from '@vueuse/firebase/useFirestore'
import { useRouteParams } from '@vueuse/router'
import { orderBy, query, where } from 'firebase/firestore'

import { logError } from '@/services/sentry.service.js'
import { UsersApi, useUserStore } from '@/modules/users/index.js'

import { ACTIVE_TEAM_STORE_ID } from './teams.constants.js'
import {
  type ITeamInvite,
  Team,
  TeamMember,
  TeamTypes,
} from './teams.models.js'
import { useTeamStore } from './teams.store.js'

export const useActiveTeamStore = defineStore(
  ACTIVE_TEAM_STORE_ID,
  () => {
    // Config
    const teamsStore = useTeamStore()
    const userStore = useUserStore()

    const {
      teamId: rootTeamId,
      teamOptions: allowedTeamIds,
      teams,
    } = storeToRefs(teamsStore)

    // State
    const pathTeamId = useRouteParams<Team['id'] | undefined>('teamId')
    const activeTeamId = computed(() => pathTeamId.value ?? null)
    const isActiveTeamValid = computed(
      () => !!allowedTeamIds.value.find((t) => t.value === activeTeamId.value),
    )

    const isLoading = ref(false)

    // - Team Documents
    const teamDoc = computed(
      () =>
        !!rootTeamId.value &&
        !!activeTeamId.value &&
        Team.document(activeTeamId.value),
    )
    const teamMembersQuery = computed(
      () =>
        !!rootTeamId.value &&
        !!activeTeamId.value &&
        query(
          TeamMember.collection,
          where('team', '==', activeTeamId.value),
          orderBy('displayName', 'asc'),
        ),
    )

    /**
     * The users active team
     */
    const team = useFirestore(teamDoc)

    /**
     * Team members of the users active team
     */
    const members = useFirestore(teamMembersQuery, [])

    // Getters
    const teamType = computed(() => !!team.value && team.value.type)
    const isMerchant = computed(
      () =>
        !!team.value &&
        !!teamType.value &&
        teamType.value === TeamTypes.merchant,
    )
    const isStandaloneMerchant = computed(
      () => !!isMerchant.value && !!team.value && !team.value.parents.length,
    )
    const isChildMerchant = computed(
      () => !!isMerchant.value && !!team.value && !!team.value.parents.length,
    )
    const is3pl = computed(() => teamType.value === TeamTypes.logistics)

    const hasPrintSettingsPermissions = computed(
      () => !isChildMerchant.value || rootTeamId.value !== activeTeamId.value,
    )

    const isReady = computed(() => !!team.value)

    const childTeams = computed(() =>
      is3pl.value
        ? teams.value?.filter((team) =>
            team.parents.includes(activeTeamId.value ?? ''),
          )
        : [],
    )

    const flags = computed(() =>
      team.value && team.value.flags ? team.value.flags : null,
    )

    // Watchers
    watch([isActiveTeamValid, activeTeamId], async ([isValid, teamId]) => {
      if (!(isValid && teamId)) return

      if (userStore.user && teamId !== userStore.user?.activeTeam)
        await userStore.updateProfile({ activeTeam: teamId })
    })

    // Actions
    const sendInvite = async (invite: Omit<ITeamInvite, 'team'>) => {
      if (activeTeamId.value === null)
        throw new Error(
          'Unauthorized: Cannot invite team members without belonging to a team.',
        )
      try {
        await new UsersApi(activeTeamId).sendInvite({
          ...invite,
          email: invite.email.toLowerCase().trim(),
          team: activeTeamId.value,
        })
      } catch (error) {
        if (error instanceof Error) logError(error)
        throw error
      }
    }

    const setActiveTeamId = async (teamId: Team['id']) => {
      if (teamId === activeTeamId.value) return
      pathTeamId.value = teamId
    }

    return {
      // State
      team,
      activeTeamId,
      pathTeamId,
      rootTeamId,
      members,
      isLoading,

      // Getters
      teamType,
      isStandaloneMerchant,
      isChildMerchant,
      is3pl,
      isReady,
      hasPrintSettingsPermissions,
      isActiveTeamValid,
      childTeams,
      flags,

      // Actions
      sendInvite,
      setActiveTeamId,
    }
  },
  {
    persist: true,
  },
)
