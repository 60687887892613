<script setup lang="ts">
import { computed } from 'vue'
import { RouterLink } from 'vue-router'
import { storeToRefs } from 'pinia'
import {
  BeakerIcon,
  CogIcon,
  RectangleGroupIcon,
  ShoppingCartIcon,
} from '@heroicons/vue/24/outline'

import { ChevronLeftIcon, FlowsIcon, SlippyButton } from '@slippy-io/ui'

import { useActiveTeamStore } from '@/modules/teams/index.js'
import { useThemeStore } from '@/modules/theme/index.js'

import BrandLogo from '@/components/brand/BrandLogo.vue'

import type { IMenuItem } from './navigation.models.js'

// Config
const themeStore = useThemeStore()
const activeTeamStore = useActiveTeamStore()

const { collapsed } = storeToRefs(themeStore)
const { is3pl } = storeToRefs(activeTeamStore)

const menuItems = computed<IMenuItem[]>(() =>
  [
    {
      name: 'Dashboard',
      route: 'dashboard',
      query: {},
      icon: RectangleGroupIcon,
      visible: true,
    },
    {
      name: 'Orders',
      route: 'orders',
      query: { status: 'ready' },
      icon: ShoppingCartIcon,
      visible: true,
    },
    {
      name: 'Templates',
      route: 'templates',
      query: {},
      icon: BeakerIcon,
      visible: !is3pl.value,
    },
    {
      name: 'Flows',
      route: 'flows',
      query: {},
      icon: FlowsIcon,
      visible: !is3pl.value,
    },
    {
      name: 'Settings',
      route: 'settings-account',
      query: {},
      icon: CogIcon,
      visible: true,
    },
  ].filter((item) => item.visible),
)
</script>

<template>
  <section class="sticky top-0 flex flex-col items-start">
    <div
      class="card flex flex-col items-start justify-start space-y-3 px-4 py-4 text-gray-700 transition-[width] sm:overflow-y-auto sm:overflow-x-hidden"
      :class="{
        'w-24': collapsed,
        'w-56': !collapsed,
      }">
      <BrandLogo />

      <RouterLink
        v-for="item in menuItems"
        :key="item.name"
        :to="{ name: item.route, query: item.query }"
        class="relative flex h-14 w-full cursor-pointer items-center space-x-2 rounded-lg px-4 transition-[width] hover:bg-gray-100"
        active-class="bg-gray-100">
        <component
          :is="item.icon"
          class="h-7 w-7 min-w-max stroke-gray-600 stroke-2 p-1" />

        <span
          class="min-w-0 truncate text-lg lowercase text-gray-600 transition-opacity first-letter:capitalize"
          :class="{
            'w-0 opacity-0': collapsed,
            'opacity-100': !collapsed,
          }"
          >{{ item.name }}</span
        >
      </RouterLink>
    </div>

    <SlippyButton
      variant="text"
      size="large"
      class="relative left-6 top-10"
      @click.prevent="themeStore.setCollapsed(!collapsed)">
      <template #leading>
        <ChevronLeftIcon
          class="transition-transform"
          :class="{ 'mx-auto rotate-180': collapsed }" />
      </template>
      <span v-if="!collapsed">collapse</span>
    </SlippyButton>
  </section>
</template>
