import { z } from 'zod'

import type { DocumentFormat } from '../orders/models/documents.js'

export interface IMeta {
  id: string
  created: Date
  updated: Date
  createdBy?: string
}

export interface ISort {
  field: string
  order: 'asc' | 'desc'
}
export interface ISortOption {
  label: string
  value: string
  defaultOrder: 'asc' | 'desc'
}

export const sortBy = (field: string, order: 'asc' | 'desc' = 'asc') => ({
  field,
  order,
})

export interface IFilter {
  field: string
  value: (string | number | Date)[]
}
export type DropdownOption<T> = { value: T; label: string }
export type ListboxOption<T = string | number | Date> = {
  value: T
  label: string
  description?: string
}

export interface IFilterOption {
  label: string
  field: string
  options: DropdownOption<string | number | Date>[]
  align?: 'left' | 'right'
}

export type Payload = { [key: string]: any }

export interface IScalar {
  x: number
  unit?: string // could tighten to 'g' | 'lb' | 'kg' | etc but would then need 'px' and 'mm' 'in'
}

export interface IPrice extends IScalar {
  /**
   * Price in selected unit
   */
  x: number
  /**
   * @default USD
   */
  unit: string // string might be easier in the short term
}

export interface IVector extends IScalar {
  x: number
  y: number
}

export interface IRect {
  top: number
  left: number
  bottom: number
  right: number
  width: number
  height: number
}

export interface ILine {
  from: string
  to: string
  start: { x: number; y: number }
  end: { x: number; y: number }
}

export const Environment = {
  local: 'local',
  development: 'development',
  production: 'production',
} as const
export type Environment = 'local' | 'development' | 'production'

export interface IDocument {
  id: string
  name: string
  src: string
  type: DocumentFormat
}
export type Documents = { [key: number]: IDocument }

export interface IPrint {
  printed: Date
  printedBy: string
}
export type Prints = { [key: string]: IPrint }

export type EditableField<T> = {
  field: keyof T
  label: string
  type: 'string' | 'email' | 'select'
  options?:
    | DropdownOption<string>[]
    | (() => DropdownOption<string>[] | Promise<DropdownOption<string>[]>)
  readonly?: boolean
  resolve?: (profile: T) => Promise<string | undefined>
}

export type SwatchColor = {
  color: string
  label: string
  showBorder?: boolean
  disabled?: boolean
  alt?: string
}
export const isSwatchColor = (color: string | object): color is SwatchColor => {
  if (typeof color === 'string') return false
  if (!('color' in color && 'label' in color)) return false
  return true
}

export type Success = { success: true }
export type Failure = { success: false; error: string }
export type Result = Success | Failure

export const uid = z.string()

export const baseModelSchema = z.object({
  id: uid,
  created: z.coerce.date(),
  updated: z.coerce.date(),
})

export type BaseModel = z.infer<typeof baseModelSchema>

export const additionalProperties = z.record(z.unknown())

export const scalarSchema = z.object({
  x: z.number(),
  unit: z.string().optional(),
})
