import { createGlobalState } from '@vueuse/shared'
import algoliasearch from 'algoliasearch/lite'
import { z } from 'zod'

const AlgoliaConfigSchema = z.object({
  appId: z.string(),
  apiKey: z.string(),
})

export const useAlgoliaClient = createGlobalState(() => {
  const config = AlgoliaConfigSchema.parse({
    appId: import.meta.env.VITE_ALGOLIA_APP_ID as string,
    apiKey: import.meta.env.VITE_ALGOLIA_SEARCH_KEY as string,
  })

  return algoliasearch(config.appId, config.apiKey)
})

export const useAlgoliaIndex = (indexName: string) => {
  const client = useAlgoliaClient()
  return client.initIndex(indexName)
}
