import { getDownloadURL, getStorage, ref as storageRef } from 'firebase/storage'

import { ApiService } from '@/services/index.js'

import type { Payload } from '../utils/index.js'

import { Asset } from './assets.models.js'

const base = 'assets'
export class AssetsApi extends ApiService<Asset> {
  constructor(team?: ApiService<any>['team']) {
    super(base, Asset, team)
  }

  createAsset(file: File) {
    return this.postFile({
      file,
    })
  }

  updateAsset(id: string, data: Partial<Asset>) {
    return this.put({ id, data })
  }

  parseSchemaAssets(schema: Payload, getUrl = false): Promise<Payload> {
    return this.post({
      path: `${base}/parseSchemaAssets`,
      data: {
        schema,
        base64: !getUrl,
      },
    }).then((data) => (data === null ? {} : data))
  }

  deleteAsset(id: string) {
    return this.delete({ id })
  }

  async downloadAsset(asset: Asset) {
    try {
      return await getDownloadURL(storageRef(getStorage(), asset.src))
    } catch (error) {
      console.error(error)
    }
  }
}
