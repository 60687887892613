import {
  collection,
  doc,
  type DocumentData,
  type DocumentSnapshot,
  type FirestoreDataConverter,
} from 'firebase/firestore'

import { FirebaseService } from '@/services/index.js'
import { Team } from '@/modules/teams/index.js'
import type { IMeta } from '@/modules/utils/index.js'

export type ImageFormat = 'png' | 'svg' | 'jpg' | 'jpeg'

export interface IAsset {
  id: string
  src: string
  name: string
  type: ImageFormat
}
export type AssetSrc = `assets://${string}`

export class Asset implements IAsset, IMeta {
  public static path = 'assets'

  id: string
  src: string
  type: ImageFormat
  name: string
  created: Date
  updated: Date
  createdBy?: string

  constructor(data: IAsset & IMeta) {
    this.id = data.id
    this.name = data.name
    this.type = data.type ?? 'png'
    this.src = data.src
    this.created = data.created
    this.updated = data.updated
    this.createdBy = data.createdBy
  }

  static converter: FirestoreDataConverter<Asset> = {
    toFirestore(asset: Asset): DocumentData {
      return {
        ...asset,
      }
    },

    fromFirestore(snapshot: DocumentSnapshot): Asset {
      const data = snapshot.data() as any
      const asset = new Asset({
        ...data,
        id: snapshot.id,
        created: data.created.toDate(),
        updated: data.updated.toDate(),
      })
      return asset
    },
  }

  static collection = (teamId: string) =>
    collection(
      new FirebaseService().db,
      Team.path,
      teamId,
      Asset.path,
    ).withConverter(Asset.converter)

  static document = (teamId: string, id: string) =>
    doc(
      new FirebaseService().db,
      Team.path,
      teamId,
      Asset.path,
      id,
    ).withConverter(Asset.converter)
}

export interface IAssetCacheItem {
  src: string
  lastUpdated: Date
}
export type AssetCache = {
  [key: Asset['id']]: IAssetCacheItem
}

export interface IAssetResponse {
  asset: Asset
  data?: string
}
