import { type FirebaseApp, getApps, initializeApp } from 'firebase/app'
import {
  type Auth,
  createUserWithEmailAndPassword,
  getAuth,
  isSignInWithEmailLink,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  signInWithEmailLink,
  type Unsubscribe,
  type User,
} from 'firebase/auth'
import { Firestore, getFirestore } from 'firebase/firestore'
import { type FirebasePerformance, getPerformance } from 'firebase/performance'
import {
  type FirebaseStorage,
  getDownloadURL,
  getStorage,
  ref,
} from 'firebase/storage'

import type { IDocument } from '@/modules/utils/index.js'
import {
  normalizeError,
  UnauthenticatedError,
} from '@/modules/utils/utils.errors.js'

import { config } from './config.service.js'
import { logError } from './sentry.service.js'

export class FirebaseService {
  app: FirebaseApp
  auth: Auth
  db: Firestore
  storage: FirebaseStorage
  performance?: FirebasePerformance

  constructor() {
    const apps = getApps()

    try {
      this.app = apps.length ? apps[0] : initializeApp(config.FIREBASE_CONFIG)
    } catch (error) {
      logError(normalizeError(error))
      throw new Error('Failed to initialise Firebase')
    }

    try {
      this.auth = getAuth(this.app)
      this.db = getFirestore(this.app)
      this.storage = getStorage(this.app)

      if (
        config.ENVIRONMENT === 'development' ||
        config.ENVIRONMENT === 'production'
      )
        this.performance = getPerformance(this.app)
    } catch (error) {
      logError(normalizeError(error))
      throw new Error('Failed to initialise Firebase services')
    }
  }

  get user(): User {
    if (!this.auth.currentUser) throw new UnauthenticatedError()
    return this.auth.currentUser
  }

  get currentUser(): User | null {
    return this.auth.currentUser
  }

  onAuth(callback: (user: User | null) => void): Unsubscribe {
    return this.auth.onIdTokenChanged(callback)
  }

  async onAuthInitialised() {
    return new Promise((resolve) => {
      onAuthStateChanged(this.auth, resolve)
    })
  }

  async signUp(email: string, password: string): Promise<User> {
    const credentials = await createUserWithEmailAndPassword(
      this.auth,
      email,
      password,
    )

    return credentials.user
  }

  async signIn(email: string, password: string): Promise<User> {
    const credentials = await signInWithEmailAndPassword(
      this.auth,
      email,
      password,
    )

    return credentials.user
  }

  public validateLoginUrl(url: string) {
    return isSignInWithEmailLink(this.auth, `${config.WEB_URL}${url}`)
  }

  public async signInWithEmailLink(email: string, url: string): Promise<User> {
    const { user } = await signInWithEmailLink(this.auth, email, url)
    return user
  }

  async signOut(): Promise<void> {
    return this.auth.signOut()
  }

  async resolveUser(): Promise<User | null> {
    return new Promise((resolve, reject) => {
      this.auth.onAuthStateChanged(resolve, reject)
    })
  }

  async getDocumentWithPublicUrl(document: IDocument): Promise<IDocument> {
    try {
      const imageRef = ref(this.storage, document.src)
      const publicDoc = { ...document, src: await getDownloadURL(imageRef) }
      return publicDoc
    } catch (e) {
      console.error(e)
      return document
    }
  }
}
