import type { App } from 'vue'
import type { Router } from 'vue-router'
import { BrowserTracing } from '@sentry/tracing'
import * as Sentry from '@sentry/vue'
import type { User } from 'firebase/auth'

import { config } from './config.service.js'

const SENTRY_TRACING_ORIGINS = {
  local: ['localhost', /^\//],
  development: ['dev-app.slippy.io', 'dev-api.slippy.io', /^\//],
  production: ['app.slippy.io', 'api.slippy.io', /^\//],
}

export const initializeSentry = (app: App, router: Router) => {
  Sentry.init({
    app,
    dsn: config.SENTRY.DSN,
    environment: config.ENVIRONMENT,
    attachStacktrace: true,
    hooks: ['activate', 'create', 'destroy', 'mount', 'update'],
    logErrors: config.ENVIRONMENT === 'local',
    normalizeDepth: 5,
    trackComponents: true,

    tracesSampleRate: 0.1,

    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: SENTRY_TRACING_ORIGINS[config.ENVIRONMENT] ?? [],
      }),
    ],
  })
}

export const logError = <T extends Error>(error: T) =>
  Sentry.captureException(error)
export const identifyUser = (user: User | null) =>
  Sentry.setUser(
    user
      ? {
          id: user.uid,
          username: user.email ? user.email : undefined,
          email: user.email ? user.email : undefined,
        }
      : null,
  )
