<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { asyncComputed } from '@vueuse/core'

import { useAssetsStore } from '@/modules/assets/index.js'
import { useActiveTeamStore } from '@/modules/teams/index.js'
import { useThemeStore } from '@/modules/theme/index.js'

import LogoLarge from '@/assets/branding/LogoLarge.vue'
import LogoSmall from '@/assets/branding/LogoSmall.vue'

const themeStore = useThemeStore()
const { team } = storeToRefs(useActiveTeamStore())
const assetStore = useAssetsStore()
const { collapsed } = storeToRefs(themeStore)

const brandLogoLarge = asyncComputed(async () =>
  team.value?.assets?.logoBig
    ? await assetStore.loadAsset(team.value.assets.logoBig).catch(() => null)
    : null,
)
const brandLogoSmall = asyncComputed(async () =>
  team.value?.assets?.logoSmall
    ? await assetStore.loadAsset(team.value.assets.logoSmall).catch(() => null)
    : null,
)
</script>

<template>
  <div class="my-3 flex h-12 min-w-full items-center justify-center transition">
    <img
      v-if="collapsed && brandLogoSmall"
      :src="brandLogoSmall" />
    <component
      :is="LogoSmall"
      v-if="collapsed && !brandLogoSmall"
      class="mx-auto my-3 h-12 min-w-full transition" />

    <img
      v-if="!collapsed && brandLogoLarge"
      :src="brandLogoLarge" />
    <component
      :is="LogoLarge"
      v-if="!collapsed && !brandLogoLarge"
      class="mx-auto my-3 h-12 min-w-full transition" />
  </div>
</template>
