import { ApiService } from '@/services/api.service.js'
import type { ITeamInvite } from '@/modules/teams/teams.models.js'

import type {
  ICreateUser,
  IRequestSignInEmail,
  IUserProfile,
} from './users.models.js'

export class UsersApi extends ApiService<IUserProfile> {
  constructor(team?: ApiService<IUserProfile>['team']) {
    super('users', undefined, team)
  }

  async createUser(data: ICreateUser): Promise<boolean> {
    return this.post({
      data: {
        ...data,
        tandcs: data.tandcs,
      },
    })
      .then(() => true)
      .catch(() => false)
  }

  async requestSignInEmail(data: IRequestSignInEmail): Promise<boolean> {
    return this.post({
      data,
      path: `users/request-sign-in-email`,
    })
      .then(() => true)
      .catch(() => false)
  }

  updateProfile(id: string, profile: Partial<IUserProfile>) {
    return this.put({ id, data: profile })
  }

  sendInvite(invite: ITeamInvite): Promise<any> {
    return this.post({ data: invite, path: 'users/invite' })
  }
}
