import type { Ref } from 'vue'
import { createGlobalState } from '@vueuse/core'
import { getAuth } from 'firebase/auth'

import type { IFilter, Payload } from '@/modules/utils/index.js'

import { config } from './config.service.js'
import { FirebaseService } from './firebase.service.js'

export const useAuthenticatedFetch = createGlobalState(() => {
  const auth = getAuth()

  return async (...[url, options]: Parameters<typeof fetch>) => {
    if (!auth.currentUser) throw new Error('Not authenticated')
    const baseUrl = config.API_BASE_URL.includes('http')
      ? config.API_BASE_URL
      : `https://${config.API_BASE_URL}`

    return fetch(`${baseUrl}${url}`, {
      ...options,
      headers: {
        'Content-Type': 'application/json',
        ...options?.headers,
        Authorization: `Bearer ${await auth.currentUser.getIdToken()}`,
      },
    })
  }
})

export type ApiMethod = 'GET' | 'POST' | 'PUT' | 'DELETE'
export interface RequestException extends Error {
  status: number
}
export class NotFoundError extends Error implements RequestException {
  name = 'NotFoundError'
  status = 404
}
export class NotAuthorizedError extends Error implements RequestException {
  name = 'NotAuthorizedError'
  status = 401
}

// TODO: Figure out how to get rid of these eslint-disable-next-line
// eslint-disable-next-line no-undef
export type RequestOptions = RequestInit & {
  method: ApiMethod
  // eslint-disable-next-line no-undef
  body?: BodyInit | FormData | null
}
interface IApiOptions {
  path?: string
  options?: Omit<RequestOptions, 'body' | 'method'>
}
interface IListOptions extends IApiOptions {
  query?: {
    [key: string]: string | number | boolean | (string | number | boolean)[]
  }
}
interface IGetOptions extends IListOptions {
  id: string
}
interface IPostOptions extends IListOptions {
  data?: Payload
}
interface IPostFileOptions extends IListOptions {
  file: File
}
interface IPutOptions extends IPostOptions {
  id: string
}
interface IDeleteOptions extends IListOptions {
  id: string
}

export interface IListResponse<T> {
  data: T[]
  count?: number
}

export const filtersToQuery = (
  filters: IFilter[],
): {
  [key: IFilter['field']]: IFilter['value']
} =>
  filters.reduce(
    (query, filter) => ({ ...query, [filter.field]: filter.value }),
    {},
  )

interface ModelConstructor<T> {
  new (...args: any[]): T
}

export abstract class ApiService<T> {
  public readonly base: string
  public readonly path: string
  protected readonly team: Ref<string | null | undefined> | undefined
  private readonly firebase: FirebaseService
  private readonly Model: ModelConstructor<T> | undefined

  constructor(
    path: string,
    Model?: ModelConstructor<T>,
    team?: Ref<string | null | undefined>,
    base: string = config.API_BASE_URL,
    firebase: FirebaseService = new FirebaseService(),
  ) {
    this.path = path
    this.team = team
    this.Model = Model
    this.firebase = firebase
    this.base = base.indexOf('http') === 0 ? base : `https://${base}`
  }

  private async queryToString(query: IListOptions['query'] = {}) {
    const queryWithTeam = {
      ...(this.team?.value ? { team: this.team.value } : {}),
      ...query,
    }

    if (!queryWithTeam || Object.keys(queryWithTeam).length === 0) return ''
    return `?${Object.entries(queryWithTeam)
      .map(([key, value]) =>
        Array.isArray(value)
          ? value.map((v) => `${key}=${v}`).join('&')
          : `${key}=${value}`,
      )
      .join('&')}`
  }

  private async getHeaders(
    options: RequestOptions,
    contentType = 'application/json',
  ): Promise<RequestOptions> {
    const token = await this.firebase.auth.currentUser?.getIdToken()

    return {
      ...options,
      headers: {
        'Content-Type': contentType,
        ...options?.headers,
        ...(token ? { Authorization: `Bearer ${token}` } : {}),
      },
    }
  }

  async request(url: string, options: RequestOptions, contentType?: string) {
    const res = await fetch(url, await this.getHeaders(options, contentType))

    // Handle 204 No Content
    if (res.status === 204) return null
    if (res.status === 404) throw new NotFoundError()

    return await res.json()
  }

  public async list({ path, query, options }: IListOptions = {}): Promise<
    IListResponse<T>
  > {
    const result = await this.request(
      `${this.base}/${path ?? this.path}${await this.queryToString(query)}`,
      { ...options, method: 'GET' },
    )

    return {
      data: (result.data ?? []).map((obj: any) =>
        this.Model ? new this.Model(obj) : (obj as T),
      ),
      ...(result.count ? { count: result.count } : {}),
    }
  }

  async get({ id, path, query, options }: IGetOptions): Promise<T> {
    return this.request(
      `${this.base}/${path ?? this.path}/${id}${await this.queryToString(
        query,
      )}`,
      { ...options, method: 'GET' },
    ).then((data) => (this.Model ? new this.Model(data) : (data as T)))
  }

  async post({
    data,
    path,
    query,
    options,
  }: IPostOptions = {}): Promise<T | null> {
    return this.request(
      `${this.base}/${path ?? this.path}${await this.queryToString(query)}`,
      {
        ...{ ...options, method: 'POST' },
        body: data ? JSON.stringify(data) : undefined,
      },
    ).then((data) => {
      if (data?.error) {
        throw new Error(data?.error.message || 'Unexpected error')
      }
      try {
        if (data && this.Model) return new this.Model()
      } catch {
        return data
      }
    })
  }

  async postFile({ file, query }: IPostFileOptions): Promise<T> {
    const token = await this.firebase.auth.currentUser?.getIdToken()
    const formData = new FormData()
    formData.append('file', file)

    const res = await fetch(
      `${this.base}/${this.path}${await this.queryToString(query)}`,
      {
        method: 'POST',
        body: formData,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )

    const data = await res.json()
    return this.Model ? new this.Model(data) : (data as T)
  }

  async put({ id, data, path, query, options }: IPutOptions): Promise<T> {
    return this.request(
      `${this.base}/${path ?? this.path}/${id}${await this.queryToString(
        query,
      )}`,
      {
        ...{ ...options, method: 'PUT' },
        body: data ? JSON.stringify(data) : undefined,
      },
    ).then((data) => (this.Model ? new this.Model(data) : (data as T)))
  }

  async delete({ id, path, query, options }: IDeleteOptions): Promise<unknown> {
    return this.request(
      `${this.base}/${path ?? this.path}/${id}${await this.queryToString(
        query,
      )}`,
      {
        ...options,
        method: 'DELETE',
      },
    )
  }
}
