import { computed, ref } from 'vue'
import { defineStore } from 'pinia'
import { useAuth } from '@vueuse/firebase/useAuth'
import { useFirestore } from '@vueuse/firebase/useFirestore'
import { getAuth } from '@firebase/auth'

import { logError } from '@/services/sentry.service.js'

import { normalizeError } from '../utils'

import { UsersApi } from './users.api.js'
import { type IUserProfile, UserProfile } from './users.models.js'

export const useUserStore = defineStore(
  'user',
  () => {
    // Config
    const { user: firebaseUser } = useAuth(getAuth())
    const usersApi = new UsersApi()

    // State
    const userDoc = computed(
      () =>
        !!firebaseUser.value?.uid &&
        UserProfile.document(firebaseUser.value?.uid),
    )
    const user = useFirestore(userDoc, undefined, {
      errorHandler: logError,
    })
    const isLoading = ref(false)

    // Getters
    const isBeta = computed(() => user.value?.isBeta === true)
    const teamId = computed(() => user.value?.team)
    const isReady = computed(() => !!user.value)

    // Actions
    const updateProfile = async (data: Partial<IUserProfile>) => {
      if (!user.value?.id) return
      isLoading.value = true
      try {
        await usersApi.updateProfile(user.value.id, data)
      } catch (error) {
        logError(normalizeError(error))
      } finally {
        isLoading.value = false
      }
    }

    return {
      // State
      user,
      isLoading,

      // Getters
      isBeta,
      teamId,
      isReady,

      // Actions
      updateProfile,
    }
  },
  {
    persist: true,
  },
)
