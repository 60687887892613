<template>
  <svg
    viewBox="0 0 3781 945"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M945 472.5C945 733.455 733.455 945 472.5 945C211.545 945 0 733.455 0 472.5C0 211.545 211.545 0 472.5 0C733.455 0 945 211.545 945 472.5Z"
      fill="#7DB8FF" />
    <path
      d="M945 472.5C945 733.455 733.455 945 472.5 945C211.545 945 0 733.455 0 472.5C0 211.545 211.545 0 472.5 0C733.455 0 945 211.545 945 472.5Z"
      fill="url(#paint0_radial_501_150)" />
    <path
      d="M945 472.5C945 733.455 733.455 945 472.5 945C211.545 945 0 733.455 0 472.5C0 211.545 211.545 0 472.5 0C733.455 0 945 211.545 945 472.5Z"
      fill="url(#paint1_linear_501_150)"
      fill-opacity="0.2" />
    <g filter="url(#filter0_bii_501_150)">
      <path
        d="M945 472.5C945 733.455 733.455 945 472.5 945C211.545 945 0 733.455 0 472.5C0 211.545 211.545 0 472.5 0C733.455 0 945 211.545 945 472.5Z"
        fill="#80BFFA"
        fill-opacity="0.2" />
      <path
        d="M945 472.5C945 733.455 733.455 945 472.5 945C211.545 945 0 733.455 0 472.5C0 211.545 211.545 0 472.5 0C733.455 0 945 211.545 945 472.5Z"
        fill="url(#paint2_radial_501_150)"
        fill-opacity="0.2" />
      <path
        d="M945 472.5C945 733.455 733.455 945 472.5 945C211.545 945 0 733.455 0 472.5C0 211.545 211.545 0 472.5 0C733.455 0 945 211.545 945 472.5Z"
        fill="url(#paint3_linear_501_150)"
        fill-opacity="0.2" />
    </g>
    <g filter="url(#filter1_dd_501_150)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M456.872 180.084C433.017 174.207 408.915 188.781 403.038 212.636L401.363 219.433C395.486 243.288 410.06 267.391 433.915 273.268L453.907 278.193C405.542 299.138 345.043 332.506 308.242 379.229C294.302 399.013 287.332 421.319 287.332 446.146C287.332 474.465 294.9 496.964 310.034 513.645C325.169 530.326 343.29 542.545 364.399 550.304C385.508 557.674 411.794 564.657 443.258 571.252C471.934 577.458 492.645 583.471 505.39 589.29C518.533 594.721 525.104 603.255 525.104 614.893C525.104 637.005 502.403 648.06 456.999 648.06C433.102 648.06 408.807 644.375 384.114 637.005C377.501 635.031 371.102 632.89 364.917 630.582C339.547 621.115 308.921 629.951 297.664 654.578L295.758 658.749C286.749 678.458 293.21 702.107 313.14 710.613C326.434 716.287 341.33 721.233 357.828 725.451C389.69 733.598 422.548 737.671 456.401 737.671C496.229 737.671 530.083 731.852 557.962 720.214C585.842 708.189 606.751 692.284 620.691 672.5C634.631 652.716 641.601 630.604 641.601 606.165C641.601 577.846 633.834 555.541 618.301 539.248C603.167 522.567 585.045 510.541 563.937 503.171C543.226 495.412 516.94 488.236 485.077 481.641C456.8 475.822 436.288 470.197 423.543 464.766C410.799 459.336 404.426 451.189 404.426 440.327C404.426 416.756 482.68 377.733 534.762 353.298L528.048 380.547C522.171 404.402 536.745 428.505 560.6 434.382L567.398 436.056C591.252 441.933 615.355 427.359 621.232 403.505L653.086 274.208C658.963 250.353 644.389 226.251 620.534 220.374L613.737 218.699C613.202 218.567 612.666 218.446 612.131 218.334L456.872 180.084Z"
        fill="#F5F5FA" />
    </g>
    <path
      d="M1285.66 666.626C1259.16 666.626 1233.04 663.63 1207.32 657.637C1181.59 651.245 1160.93 643.055 1145.34 633.067L1178.67 555.161C1193.09 564.349 1210.24 571.741 1230.12 577.334C1250 582.528 1269.68 585.124 1289.17 585.124C1307.1 585.124 1319.77 583.327 1327.18 579.731C1334.97 575.736 1338.87 570.142 1338.87 562.951C1338.87 555.76 1334.19 550.766 1324.84 547.969C1315.87 544.773 1301.45 541.777 1281.57 538.98C1256.24 535.784 1234.6 531.589 1216.67 526.395C1199.13 521.202 1183.93 511.613 1171.07 497.63C1158.2 483.647 1151.77 464.07 1151.77 438.901C1151.77 418.126 1157.81 399.748 1169.9 383.767C1181.98 367.387 1199.52 354.602 1222.52 345.413C1245.91 335.825 1273.78 331.031 1306.13 331.031C1329.13 331.031 1351.93 333.428 1374.54 338.222C1397.14 343.016 1416.05 349.808 1431.25 358.598L1397.92 435.904C1369.86 419.924 1339.46 411.933 1306.71 411.933C1289.17 411.933 1276.31 414.131 1268.12 418.525C1259.94 422.52 1255.85 427.914 1255.85 434.706C1255.85 442.297 1260.33 447.69 1269.29 450.886C1278.26 453.683 1293.07 456.679 1313.73 459.875C1339.85 463.871 1361.48 468.465 1378.63 473.659C1395.78 478.853 1410.59 488.441 1423.07 502.424C1435.93 516.008 1442.36 535.185 1442.36 559.955C1442.36 580.33 1436.32 598.708 1424.23 615.088C1412.15 631.069 1394.22 643.654 1370.44 652.843C1347.06 662.032 1318.8 666.626 1285.66 666.626Z"
      fill="#374151" />
    <path
      d="M1490.23 216.569H1601.32V661.233H1490.23V216.569Z"
      fill="#374151" />
    <path
      d="M1673.51 336.424H1784.6V661.233H1673.51V336.424ZM1729.06 300.468C1708.79 300.468 1692.42 294.675 1679.95 283.089C1667.47 271.503 1661.24 257.12 1661.24 239.941C1661.24 222.761 1667.47 208.379 1679.95 196.793C1692.42 185.207 1708.79 179.414 1729.06 179.414C1749.33 179.414 1765.7 185.007 1778.17 196.193C1790.65 206.98 1796.88 220.963 1796.88 238.143C1796.88 256.121 1790.65 271.103 1778.17 283.089C1765.7 294.675 1749.33 300.468 1729.06 300.468Z"
      fill="#374151" />
    <path
      d="M2056.18 331.031C2085.02 331.031 2111.14 338.022 2134.52 352.005C2158.3 365.589 2177.01 385.165 2190.65 410.735C2204.68 435.904 2211.7 465.269 2211.7 498.828C2211.7 532.388 2204.68 561.952 2190.65 587.522C2177.01 612.691 2158.3 632.268 2134.52 646.251C2111.14 659.834 2085.02 666.626 2056.18 666.626C2019.15 666.626 1989.72 655.24 1967.89 632.467V777.492H1856.8V336.424H1962.63V368.785C1983.28 343.616 2014.47 331.031 2056.18 331.031ZM2032.79 576.135C2052.28 576.135 2068.26 569.343 2080.73 555.76C2093.2 541.777 2099.44 522.8 2099.44 498.828C2099.44 474.857 2093.2 456.08 2080.73 442.496C2068.26 428.513 2052.28 421.522 2032.79 421.522C2013.3 421.522 1997.32 428.513 1984.84 442.496C1972.37 456.08 1966.13 474.857 1966.13 498.828C1966.13 522.8 1972.37 541.777 1984.84 555.76C1997.32 569.343 2013.3 576.135 2032.79 576.135Z"
      fill="#374151" />
    <path
      d="M2462.71 331.031C2491.56 331.031 2517.67 338.022 2541.06 352.005C2564.84 365.589 2583.55 385.165 2597.19 410.735C2611.22 435.904 2618.24 465.269 2618.24 498.828C2618.24 532.388 2611.22 561.952 2597.19 587.522C2583.55 612.691 2564.84 632.268 2541.06 646.251C2517.67 659.834 2491.56 666.626 2462.71 666.626C2425.68 666.626 2396.25 655.24 2374.42 632.467V777.492H2263.34V336.424H2369.16V368.785C2389.82 343.616 2421 331.031 2462.71 331.031ZM2439.32 576.135C2458.81 576.135 2474.8 569.343 2487.27 555.76C2499.74 541.777 2505.98 522.8 2505.98 498.828C2505.98 474.857 2499.74 456.08 2487.27 442.496C2474.8 428.513 2458.81 421.522 2439.32 421.522C2419.84 421.522 2403.85 428.513 2391.38 442.496C2378.91 456.08 2372.67 474.857 2372.67 498.828C2372.67 522.8 2378.91 541.777 2391.38 555.76C2403.85 569.343 2419.84 576.135 2439.32 576.135Z"
      fill="#374151" />
    <path
      d="M2995.16 336.424L2858.34 674.417C2842.36 714.369 2822.87 742.335 2799.88 758.316C2776.88 774.696 2748.81 782.886 2715.68 782.886C2698.92 782.886 2681.97 780.089 2664.82 774.496C2648.05 769.302 2634.41 762.311 2623.89 753.521L2662.48 673.818C2668.71 679.81 2676.12 684.405 2684.69 687.601C2693.66 691.197 2702.62 692.994 2711.59 692.994C2722.11 692.994 2730.69 690.797 2737.32 686.402C2744.33 682.008 2750.18 675.016 2754.86 665.428L2619.21 336.424H2733.22L2810.99 534.785L2889.33 336.424H2995.16Z"
      fill="#374151" />
    <path
      d="M3258.78 344.698H3369.87V669.508H3258.78V344.698ZM3314.32 308.741C3294.05 308.741 3277.68 302.948 3265.21 291.362C3252.73 279.776 3246.5 265.394 3246.5 248.214C3246.5 231.035 3252.73 216.652 3265.21 205.066C3277.68 193.48 3294.05 187.687 3314.32 187.687C3334.59 187.687 3350.96 193.28 3363.43 204.467C3375.91 215.254 3382.14 229.237 3382.14 246.416C3382.14 264.395 3375.91 279.377 3363.43 291.362C3350.96 302.948 3334.59 308.741 3314.32 308.741Z"
      fill="#374151" />
    <path
      d="M3601.09 664.901C3567.18 664.901 3536.58 657.71 3509.3 643.327C3482.01 628.944 3460.58 608.968 3444.98 583.399C3429.78 557.83 3422.18 528.865 3422.18 496.504C3422.18 464.542 3429.78 435.777 3444.98 410.207C3460.58 384.638 3481.82 364.862 3508.72 350.879C3536 336.496 3566.79 329.305 3601.09 329.305C3635.4 329.305 3666.19 336.496 3693.48 350.879C3720.76 364.862 3742 384.638 3757.21 410.207C3772.41 435.377 3780.01 464.143 3780.01 496.504C3780.01 528.865 3772.41 557.83 3757.21 583.399C3742 608.968 3720.76 628.944 3693.48 643.327C3666.19 657.71 3635.4 664.901 3601.09 664.901ZM3601.09 574.41C3620.58 574.41 3636.57 567.618 3649.04 554.034C3661.51 540.051 3667.75 520.874 3667.75 496.504C3667.75 472.532 3661.51 453.755 3649.04 440.171C3636.57 426.588 3620.58 419.796 3601.09 419.796C3581.61 419.796 3565.62 426.588 3553.15 440.171C3540.68 453.755 3534.44 472.532 3534.44 496.504C3534.44 520.874 3540.68 540.051 3553.15 554.034C3565.62 567.618 3581.61 574.41 3601.09 574.41Z"
      fill="#374151" />
    <ellipse
      cx="3097.6"
      cy="768.472"
      rx="76.832"
      ry="78.75"
      fill="#0EA5E9" />
    <defs>
      <filter
        id="filter0_bii_501_150"
        x="-241.92"
        y="-241.92"
        width="1428.84"
        height="1428.84"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB">
        <feFlood
          flood-opacity="0"
          result="BackgroundImageFix" />
        <feGaussianBlur
          in="BackgroundImageFix"
          stdDeviation="120.96" />
        <feComposite
          in2="SourceAlpha"
          operator="in"
          result="effect1_backgroundBlur_501_150" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_backgroundBlur_501_150"
          result="shape" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha" />
        <feOffset
          dx="-63"
          dy="63" />
        <feGaussianBlur stdDeviation="31.5" />
        <feComposite
          in2="hardAlpha"
          operator="arithmetic"
          k2="-1"
          k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.2 0" />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect2_innerShadow_501_150" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha" />
        <feOffset
          dx="63"
          dy="-63" />
        <feGaussianBlur stdDeviation="31.5" />
        <feComposite
          in2="hardAlpha"
          operator="arithmetic"
          k2="-1"
          k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.253333 0 0 0 0 0.253333 0 0 0 0 0.253333 0 0 0 0.2 0" />
        <feBlend
          mode="normal"
          in2="effect2_innerShadow_501_150"
          result="effect3_innerShadow_501_150" />
      </filter>
      <filter
        id="filter1_dd_501_150"
        x="264.196"
        y="163.358"
        width="413.328"
        height="605.161"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB">
        <feFlood
          flood-opacity="0"
          result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha" />
        <feOffset dy="7.71197" />
        <feGaussianBlur stdDeviation="7.71197" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.06 0" />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_501_150" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha" />
        <feOffset dy="7.71197" />
        <feGaussianBlur stdDeviation="11.568" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
        <feBlend
          mode="normal"
          in2="effect1_dropShadow_501_150"
          result="effect2_dropShadow_501_150" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect2_dropShadow_501_150"
          result="shape" />
      </filter>
      <radialGradient
        id="paint0_radial_501_150"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(472.5 472.5) rotate(90) scale(472.5)">
        <stop
          stop-color="white"
          stop-opacity="0.44" />
        <stop
          offset="0.98116"
          stop-color="#77C4FC" />
        <stop
          offset="0.9999"
          stop-color="#6BACF9" />
      </radialGradient>
      <linearGradient
        id="paint1_linear_501_150"
        x1="-271.687"
        y1="-177.188"
        x2="1464.75"
        y2="1405.69"
        gradientUnits="userSpaceOnUse">
        <stop
          offset="0.447328"
          stop-color="white"
          stop-opacity="0.711401" />
        <stop offset="0.664753" />
        <stop
          offset="1"
          stop-opacity="0" />
      </linearGradient>
      <radialGradient
        id="paint2_radial_501_150"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(472.5 472.5) rotate(90) scale(472.5)">
        <stop
          stop-color="white"
          stop-opacity="0.44" />
        <stop
          offset="0.885417"
          stop-color="#4A9AFB" />
        <stop
          offset="0.9999"
          stop-color="#4A9AFB" />
      </radialGradient>
      <linearGradient
        id="paint3_linear_501_150"
        x1="-271.687"
        y1="-177.188"
        x2="1464.75"
        y2="1405.69"
        gradientUnits="userSpaceOnUse">
        <stop
          offset="0.447328"
          stop-color="white"
          stop-opacity="0.711401" />
        <stop offset="0.664753" />
        <stop
          offset="1"
          stop-opacity="0" />
      </linearGradient>
    </defs>
  </svg>
</template>
