import { computed, reactive, ref, watch } from 'vue'
import { defineStore, storeToRefs } from 'pinia'
import {
  onSnapshot,
  orderBy,
  query,
  type Unsubscribe,
} from 'firebase/firestore'

import { isAfter, isDateInRange } from '@/services/dates.service.js'
import { useActiveTeamStore } from '@/modules/teams/index.js'
import { type ISort, sortBy } from '@/modules/utils/utils.models.js'

import { AssetsApi } from './assets.api.js'
import { ASSET_STORE_ID } from './assets.constants.js'
import { Asset, type AssetCache } from './assets.models.js'

export const useAssetsStore = defineStore(
  ASSET_STORE_ID,
  () => {
    // Config
    const { team, activeTeamId: teamId } = storeToRefs(useActiveTeamStore())
    const assetsApi = new AssetsApi(teamId)

    // State
    const assets = ref<Asset[]>([])
    const assetCache = reactive<AssetCache>({})
    const sort = ref<ISort>(sortBy('created', 'desc'))
    const isLoading = ref(true)
    const unsub = ref<Unsubscribe>()

    // Getters
    const isEmpty = computed(() => assets.value.length === 0)
    const count = computed<number>(() => assets.value.length)

    // Actions
    const setup = (teamId: string) => {
      if (unsub.value) unsub.value()

      unsub.value = onSnapshot(
        query(
          Asset.collection(teamId),
          orderBy(sort.value.field, sort.value.order),
        ),
        {
          next: async (snapshot) => {
            assets.value = await Promise.all(
              snapshot.docs.map((doc) => doc.data()),
            )
            isLoading.value = false
          },
        },
      )
    }

    const reset = () => {
      assets.value = []
      unsub.value?.()
      unsub.value = undefined
    }

    const getAssetById = (id: Asset['id']): Asset | undefined =>
      assets.value.find((asset) => asset.id === id)

    const loadAsset = async (id: Asset['id']) => {
      const asset = getAssetById(id)
      if (!asset) throw new Error(`Asset<${id}> not found`)

      if (
        assetCache[id] &&
        (isAfter(assetCache[id].lastUpdated, asset.updated) ||
          isDateInRange(assetCache[id].lastUpdated, 15, 'minutes'))
      )
        return assetCache[id].src

      const assetSrc = await assetsApi.downloadAsset(asset)
      if (!assetSrc) throw new Error(`Couldn't download Asset<${id}>`)

      assetCache[id] = {
        src: assetSrc,
        lastUpdated: new Date(),
      }

      return assetSrc
    }

    const loadAssets = async (
      ids: string[],
    ): Promise<{ [key: Asset['id']]: string }> => {
      const assetSrcs = await Promise.all(
        ids.map(async (id) => {
          try {
            return {
              [id]: await loadAsset(id.replace('asset://', '')),
            }
          } catch (error) {
            return {}
          }
        }),
      )
      const assets = assetSrcs.reduce(
        (assets, asset) => ({ ...assets, ...asset }),
        {},
      )

      return assets
    }

    const setSort = (s: ISort) => {
      sort.value = s
      if (team.value) setup(team.value.id)
    }

    // Asset CRUD
    const addAsset = async (file: File) => assetsApi.createAsset(file)

    const updateAsset = async (id: string, data: Partial<Asset>) => {
      try {
        await assetsApi.updateAsset(id, { ...data, id })
      } catch {
        alert('Error updating template')
      }
    }
    const removeAsset = async (_id: string) => {
      throw new Error('Not implemented')
    }

    // Watchers
    watch(team, (newTeam) => {
      if (!newTeam) return reset()
      setup(newTeam.id)
    })

    if (team.value) setup(team.value.id)

    return {
      // State
      assets,
      count,
      sort,
      isLoading,

      // Getters
      isEmpty,

      // Actions
      addAsset,
      getAssetById,
      loadAsset,
      loadAssets,
      setSort,
      updateAsset,
      removeAsset,
    }
  },
  {
    persist: true,
  },
)
