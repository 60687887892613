<script setup lang="ts">
import { computed } from 'vue'
import { storeToRefs } from 'pinia'

import { useActiveTeamStore, useTeamStore } from '@/modules/teams/index.js'

import Autocomplete, {
  type IComboboxOption,
} from '@/components/Autocomplete.vue'

const activeTeamStore = useActiveTeamStore()
const { activeTeamId } = storeToRefs(activeTeamStore)
const { teamOptions } = storeToRefs(useTeamStore())

const props = withDefaults(
  defineProps<{
    showSelf?: boolean
    preSelect?: boolean
    redirectPath?: string
  }>(),
  {
    showSelf: true,
    preSelect: true,
    redirectPath: undefined,
  },
)

const emit = defineEmits<{
  (e: 'change', value: string): void
}>()

const updateActiveTeam = async (selected: IComboboxOption) => {
  try {
    activeTeamStore.setActiveTeamId(selected.value)
    emit('change', selected.value)
  } catch {
    // TODO: handle error
  }
}

const visibleTeamOptions = computed(() =>
  props.showSelf ? teamOptions.value : teamOptions.value.slice(1),
)

const selectedTeamOption = computed(() =>
  props.showSelf
    ? visibleTeamOptions.value.find((team) => team.value === activeTeamId.value)
    : visibleTeamOptions.value[0],
)
</script>

<template>
  <Autocomplete
    :options="visibleTeamOptions"
    :selected="preSelect ? selectedTeamOption : undefined"
    variant="lg"
    placeholder="Type to search..."
    @change="updateActiveTeam" />
</template>
