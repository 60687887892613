import {
  createRouter,
  createWebHistory,
  type NavigationGuardNext,
  type RouteLocationNormalized,
} from 'vue-router'
import { type Pinia, storeToRefs } from 'pinia'

import { toTitleCase } from '@/services/text.service.js'
import { useAuthStore } from '@/modules/auth/index.js'
import { TeamTypes, useTeamStore } from '@/modules/teams/index.js'

import MainLayout from '@/layouts/MainLayout.vue'

export const generateRouter = (pinia: Pinia) => {
  const { isAuthenticated } = storeToRefs(useAuthStore())
  const { fallbackTeam } = storeToRefs(useTeamStore(pinia))

  const unauthenticatedGuard = (
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext,
  ) => {
    if (isAuthenticated.value) return next(`/${fallbackTeam.value}`)
    return next()
  }

  const authenticatedGuard = (
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext,
  ) => {
    if (!isAuthenticated.value) return next({ name: 'sign-up' })
    return next()
  }

  const router = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    routes: [
      // Authentication routes
      {
        path: '/sign-up',
        name: 'sign-up',
        component: () => import('@/views/auth/SignUpView.vue'),
        beforeEnter: unauthenticatedGuard,
        meta: {
          title: 'sign up',
        },
      },
      {
        path: '/sign-in',
        name: 'sign-in',
        beforeEnter: unauthenticatedGuard,
        meta: {
          title: 'sign in',
        },
        component: () => import('@/views/auth/SignInView.vue'),
      },
      {
        path: '/confirmation',
        name: 'confirmation',
        beforeEnter: unauthenticatedGuard,
        meta: {
          title: 'email sent',
        },
        component: () => import('@/views/auth/ConfirmationView.vue'),
      },
      {
        path: '/auth',
        name: 'auth',
        beforeEnter: unauthenticatedGuard,
        meta: {
          title: 'authentication',
        },
        component: () => import('@/views/auth/AuthenticationView.vue'),
      },

      // Legacy redirect routes
      {
        path: '/',
        redirect: () => {
          return { path: `/${fallbackTeam.value}` }
        },
      },
      {
        path: '/:legacyRoute(orders|templates|flows|settings)/:legacyId?',
        redirect: (to) => {
          return {
            path: `/${fallbackTeam.value}${to.path === '/' ? '' : to.path}`,
          }
        },
      },

      // App Routes
      {
        path: '/:teamId',
        component: MainLayout,
        beforeEnter: authenticatedGuard,
        children: [
          {
            path: '/:teamId/',
            name: 'dashboard',
            component: () => import('@/views/dashboard/DashboardView.vue'),
            meta: {
              title: 'dashboard',
            },
          },
          {
            path: 'orders/:orderId?',
            name: 'orders',
            component: () => import('@/views/orders/OrdersView.vue'),
            meta: {
              title: 'orders',
            },
          },
          {
            path: 'templates',
            name: 'templates',
            component: () => import('@/views/templates/TemplatesView.vue'),
            meta: {
              title: 'templates',
              accessControlledTeamTypes: [TeamTypes.merchant],
            },
          },
          {
            path: 'templates/:templateId',
            name: 'template',
            component: () => import('@/views/templates/TemplateView.vue'),
            meta: {
              accessControlledTeamTypes: [TeamTypes.merchant],
            },
          },
          {
            path: 'flows/:flowId?',
            name: 'flows',
            component: () => import('@/views/flows/FlowsView.vue'),
            meta: {
              needsTeamSwitch: true,
              accessControlledTeamTypes: [TeamTypes.merchant],
            },
          },

          {
            path: 'settings',
            // name: 'settings',
            redirect: '/settings/account',
            meta: {},
            component: () => import('@/views/settings/SettingsView.vue'),
            children: [
              {
                path: 'account',
                name: 'settings-account',
                components: {
                  default: () =>
                    import('@/modules/settings/components/AccountDetails.vue'),
                  actions: () =>
                    import('@/modules/settings/components/TeamDetails.vue'),
                },
                meta: {
                  title: 'account details',
                },
              },
              {
                path: 'printers',
                name: 'settings-printers',
                components: {
                  default: () =>
                    import('@/modules/settings/components/PrinterSettings.vue'),
                  actions: () =>
                    import('@/modules/settings/components/PrinterActions.vue'),
                },
                meta: {
                  title: 'printers',
                },
              },
              {
                path: 'integrations/:integrationId?',
                name: 'settings-integrations',
                components: {
                  default: () =>
                    import(
                      '@/modules/settings/components/IntegrationSettings.vue'
                    ),
                  actions: () =>
                    import(
                      '@/modules/settings/components/IntegrationActions.vue'
                    ),
                },
                meta: {
                  title: 'integrations',
                },
              },
              {
                path: 'billing',
                name: 'settings-billing',
                components: {
                  default: () =>
                    import('@/modules/settings/components/BillingSettings.vue'),
                  actions: () =>
                    import('@/modules/settings/components/BillingActions.vue'),
                },
                meta: {
                  title: 'billing',
                },
              },
            ],
          },
        ],
      },
    ],
  })

  router.beforeResolve((to, from, next) => {
    if (to.meta.title)
      document.title = `${toTitleCase(to.meta.title as string)} | Slippy`

    next()
  })
  router.afterEach((to) => window.analytics?.page?.(to.name))
  return router
}
