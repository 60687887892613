import type { DocumentData, FirestoreDataConverter } from 'firebase/firestore'
import { collection, doc, DocumentSnapshot } from 'firebase/firestore'

import { FirebaseService } from '@/services/index.js'
import type { IPaperSize, PaperSize } from '@/modules/paper-sizes/index.js'
import type { IMeta } from '@/modules/utils/index.js'

export enum TeamTypes {
  merchant = 'merchant',
  logistics = '3pl',
  admin = 'admin',
}

export type TeamAssets = {
  logoBig?: string
  logoSmall?: string
}

export type TeamLevelFlags = {
  duplexSettings?: boolean
  customFonts?: string[]
}

export interface ITeam {
  id: string
  name: string
  type: TeamTypes.merchant | TeamTypes.logistics | TeamTypes.admin
  parents: ITeam['id'][]
  children: ITeam['id'][]
  defaultTemplate?: string
  defaultPrinter?: string
  assets?: TeamAssets
  ignoredPaperSizes?: IPaperSize['key'][]
  flags?: TeamLevelFlags
}

export class Team implements ITeam, IMeta {
  public static path = 'teams'

  id: string
  name: string
  type: TeamTypes.merchant | TeamTypes.logistics | TeamTypes.admin
  parents: ITeam['id'][]
  children: ITeam['id'][]
  defaultTemplate?: string
  defaultPrinter?: string
  assets?: TeamAssets
  ignoredPaperSizes?: PaperSize['key'][]
  flags?: TeamLevelFlags

  created: Date
  updated: Date
  createdBy?: string

  constructor(data: ITeam & IMeta) {
    this.id = data.id
    this.name = data.name
    this.type = data.type
    this.parents = data.parents ?? []
    this.children = data.children ?? []
    this.defaultTemplate = data.defaultTemplate
    this.defaultPrinter = data.defaultPrinter
    this.assets = data.assets
    this.ignoredPaperSizes = data.ignoredPaperSizes
    this.flags = data.flags
    this.created = data.created
    this.updated = data.updated
    this.createdBy = data.createdBy
  }

  static converter: FirestoreDataConverter<Team> = {
    toFirestore(team: Team): DocumentData {
      return {
        ...team,
      }
    },

    fromFirestore(snapshot: DocumentSnapshot): Team {
      const data = snapshot.data()

      return new Team({
        id: snapshot.id,
        name: data?.name,
        type: data?.type,
        parents: data?.parents,
        children: data?.children,
        defaultTemplate: data?.defaultTemplate,
        defaultPrinter: data?.defaultPrinter,
        assets: data?.assets,
        ignoredPaperSizes: data?.ignoredPaperSizes,
        flags: data?.flags,

        created: data?.created?.toDate(),
        updated: data?.updated?.toDate(),
        createdBy: data?.createdBy,
      })
    },
  }

  static collection = collection(
    new FirebaseService().db,
    Team.path,
  ).withConverter(Team.converter)

  static document = (id: string) =>
    doc(new FirebaseService().db, Team.path, id).withConverter(Team.converter)
}

export interface ITeamMember {
  id: string
  email: string
  displayName: string
  isAdmin: boolean
  // TODO: Add invite status
}

export class TeamMember implements ITeamMember {
  public static path = 'users'

  id: string
  email: string
  displayName: string
  isAdmin: boolean

  constructor(data: ITeamMember) {
    this.id = data?.id
    this.email = data?.email
    this.displayName = data?.displayName
    this.isAdmin = data?.isAdmin ?? false
  }

  static converter: FirestoreDataConverter<TeamMember> = {
    toFirestore(user: TeamMember): DocumentData {
      return {
        ...user,
      }
    },

    fromFirestore(snapshot: DocumentSnapshot): TeamMember {
      const data = snapshot.data()
      if (!data) throw new Error('Team member not found.')

      return new TeamMember({
        id: snapshot.id,
        email: data?.email,
        displayName: data?.displayName,
        isAdmin: data?.isAdmin,
      })
    },
  }

  static collection = collection(
    new FirebaseService().db,
    TeamMember.path,
  ).withConverter(TeamMember.converter)
}

export interface ITeamInvite {
  displayName: string
  email: string
  team: string
}
