import type { Ref } from 'vue'
import { defineStore } from 'pinia'
import { useStorage } from '@vueuse/core'

export const THEME_STORE_NAME = 'theme'

export const useThemeStore = defineStore(
  THEME_STORE_NAME,
  () => {
    // State
    const collapsed: Ref<boolean> = useStorage(
      `${THEME_STORE_NAME}-collapsed`,
      false,
    )

    // Actions
    const setCollapsed = (c: typeof collapsed.value) => (collapsed.value = c)

    return {
      collapsed,
      setCollapsed,
    }
  },
  {
    persist: true,
  },
)
