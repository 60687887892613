import {
  collection,
  doc,
  type DocumentData,
  type DocumentSnapshot,
  type FirestoreDataConverter,
} from 'firebase/firestore'

import { FirebaseService } from '@/services/firebase.service.js'
import type { IMeta } from '@/modules/utils/utils.models.js'

export interface ICreateUser {
  displayName: string
  email: string
  company: string
  tandcs: string
}
export interface IRequestSignInEmail {
  email: string
  redirectUrl?: string
}

export interface IUserProfile {
  id: string
  email: string
  displayName: string
  team: string
  activeTeam: string
  parentTeams: string[]
  childrenTeams: string[]
  isBeta: boolean
  tandcs?: Date
}

export class UserProfile implements IUserProfile, IMeta {
  public static path = 'users'

  id: string
  email: string
  displayName: string
  team: string
  activeTeam: string
  parentTeams: string[]
  childrenTeams: string[]
  isBeta: boolean
  tandcs?: Date
  created: Date
  updated: Date
  createdBy?: string

  constructor(data: IUserProfile & IMeta) {
    this.id = data.id
    this.email = data.email
    this.displayName = data.displayName
    this.team = data.team
    this.activeTeam = data.activeTeam ?? data.team
    this.parentTeams = data.parentTeams ?? []
    this.childrenTeams = data.childrenTeams ?? []
    this.isBeta = data.isBeta ?? false
    this.tandcs = data.tandcs
    this.created = data.created
    this.updated = data.updated
    this.createdBy = data.createdBy
  }

  static converter: FirestoreDataConverter<UserProfile> = {
    toFirestore(user: UserProfile): DocumentData {
      return {
        ...user,
      }
    },

    fromFirestore(snapshot: DocumentSnapshot): UserProfile {
      const data = snapshot.data() as UserProfile & IMeta

      const user = new UserProfile({
        id: snapshot.id,
        email: data.email,
        displayName: data.displayName,
        team: data.team,
        activeTeam: data.activeTeam,
        parentTeams: data.parentTeams ?? [],
        childrenTeams: data.childrenTeams ?? [],
        isBeta: data.isBeta,
        tandcs: data.tandcs,
        created: data.created,
        updated: data.updated,
        createdBy: data.createdBy,
      })

      return user
    },
  }

  static collection = collection(
    new FirebaseService().db,
    UserProfile.path,
  ).withConverter(UserProfile.converter)

  static document = (id: string) =>
    doc(new FirebaseService().db, UserProfile.path, id).withConverter(
      UserProfile.converter,
    )
}
