import { createApp } from 'vue'
import Toast, { type PluginOptions, POSITION } from 'vue-toastification'
import { createPinia, storeToRefs } from 'pinia'
import { createPersistedState } from 'pinia-plugin-persistedstate'
import { until } from '@vueuse/core'
import { Buffer } from 'buffer'

import { initializeSentry } from '@/services/sentry.service.js'
import { useAuthStore } from '@/modules/auth/index.js'
import { useUserStore } from '@/modules/users/index.js'

import App from '@/App.vue'
import { generateRouter } from '@/router.js'

import '@/main.css'
import 'unfonts.css'

// eslint-disable-next-line no-undef
globalThis.Buffer = Buffer

// Configure Pinia
const pinia = createPinia()
pinia.use(
  createPersistedState({
    key: (id) => `__cache__${id}`,
  }),
)

// Setup environment and plugins
const app = createApp(App)

app.use(Toast, {
  maxToasts: 4,
  position: POSITION.BOTTOM_RIGHT,
} as PluginOptions)

app.use(pinia)

const {
  isReady: isAuthReady,
  isAuthenticated,
  teamId,
} = storeToRefs(useAuthStore(pinia))
const { isReady: isUserReady } = storeToRefs(useUserStore(pinia))

await until(isAuthReady).toBe(true)
if (isAuthenticated.value) {
  await until(teamId).not.toBeNull()
  await until(isUserReady).toBe(true)
}

const router = generateRouter(pinia)

// Add Sentry error reporting and tracing
initializeSentry(app, router)

app.use(router)

app.mount('#app')
