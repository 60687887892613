import { ref, watch } from 'vue'
import { createGlobalState, useSessionStorage } from '@vueuse/core'
import { useAuth } from '@vueuse/firebase'
import faunadb from 'faunadb'
import { getAuth } from 'firebase/auth'

import { useAuthenticatedFetch } from '@/services/api.service.js'

export type FaunaTokenAuthenticated = {
  authenticated: true
  token: string
}
export type FaunaTokenUnauthenticated = {
  authenticated: false
}

export type FaunaToken = FaunaTokenAuthenticated | FaunaTokenUnauthenticated

export const getAuthToken = async (): Promise<FaunaTokenAuthenticated> => {
  const fetch = useAuthenticatedFetch()
  const res = await fetch('/auth/fauna-token')

  if (!res.ok) throw new Error('Failed to get Fauna token')

  const data = await res.json()
  if (!('token' in data && typeof data.token === 'string'))
    throw new Error('Failed to get Fauna token')

  return { authenticated: true, token: data.token }
}

export const useFaunaAuthToken = createGlobalState(() => {
  const { isAuthenticated } = useAuth(getAuth())
  const token = useSessionStorage<FaunaToken>('auth.fauna', {
    authenticated: false,
  })

  watch(
    isAuthenticated,
    async (authenticated) => {
      if (!authenticated) {
        token.value = { authenticated: false }
        return
      }

      token.value = await getAuthToken()
    },
    { immediate: true },
  )

  return token
})

export const useFaunaClient = createGlobalState(() => {
  const token = useFaunaAuthToken()
  const client = ref<faunadb.Client | null>(null)

  watch(
    token,
    async (token) => {
      if (!token.authenticated) {
        client.value = null
        return
      }

      client.value = new faunadb.Client({ secret: token.token })
    },
    { immediate: true },
  )

  return client
})
