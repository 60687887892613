import type { IAuth, IRule } from './auth.models.js'

export const AUTH_STORE_ID = 'auth'

export const AUTH_PROPS_DEFAULT: IAuth = {
  displayName: '',
  email: '',
  company: '',
  tandcs: null,
}

export const AUTH_EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export const AUTH_PASSWORD_RULES: IRule[] = [
  {
    id: 1,
    label: 'at least eight characters long',
    regex: /^.{8,}$/,
  },
  {
    id: 2,
    label: 'at least one lowercase, uppercase letter and number',
    regex: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/,
  },
  {
    id: 3,
    label: 'at least one special character',
    regex: /^(?=.*[!@#$%^&*])/,
  },
]
