import dayjs from 'dayjs/esm'
import Calendar from 'dayjs/esm/plugin/calendar'
import LocalizedFormat from 'dayjs/esm/plugin/localizedFormat'

dayjs.extend(Calendar)
dayjs.extend(LocalizedFormat)

export const dateToCalendar = (date: Date) => dayjs(date).calendar()

export const dateToString = (date: Date) => dayjs(date).format('lll')

export const isAfter = (first: Date, second: Date) =>
  dayjs(first).isAfter(second)

export const isDateInRange = (
  date: Date,
  duration: number,
  unit: dayjs.ManipulateType,
) => {
  const point = dayjs(date).add(duration, unit)
  return point.isAfter(dayjs())
}
