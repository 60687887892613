<template>
  <svg
    viewBox="0 0 256 256"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M256 128C256 198.692 198.692 256 128 256C57.3076 256 0 198.692 0 128C0 57.3076 57.3076 0 128 0C198.692 0 256 57.3076 256 128Z"
      fill="#7DB8FF" />
    <path
      d="M256 128C256 198.692 198.692 256 128 256C57.3076 256 0 198.692 0 128C0 57.3076 57.3076 0 128 0C198.692 0 256 57.3076 256 128Z"
      fill="url(#paint0_radial_858_13162)" />
    <path
      d="M256 128C256 198.692 198.692 256 128 256C57.3076 256 0 198.692 0 128C0 57.3076 57.3076 0 128 0C198.692 0 256 57.3076 256 128Z"
      fill="url(#paint1_linear_858_13162)"
      fill-opacity="0.2" />
    <g filter="url(#filter0_bii_858_13162)">
      <path
        d="M256 128C256 198.692 198.692 256 128 256C57.3075 256 0 198.692 0 128C0 57.3075 57.3075 0 128 0C198.692 0 256 57.3075 256 128Z"
        fill="#80BFFA"
        fill-opacity="0.2" />
      <path
        d="M256 128C256 198.692 198.692 256 128 256C57.3075 256 0 198.692 0 128C0 57.3075 57.3075 0 128 0C198.692 0 256 57.3075 256 128Z"
        fill="url(#paint2_radial_858_13162)"
        fill-opacity="0.2" />
      <path
        d="M256 128C256 198.692 198.692 256 128 256C57.3075 256 0 198.692 0 128C0 57.3075 57.3075 0 128 0C198.692 0 256 57.3075 256 128Z"
        fill="url(#paint3_linear_858_13162)"
        fill-opacity="0.2" />
    </g>
    <g filter="url(#filter1_dd_858_13162)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M123.766 48.7851C117.304 47.1931 110.775 51.1411 109.183 57.6034L108.729 59.4448C107.137 65.9071 111.085 72.4364 117.547 74.0285L122.963 75.3627C109.861 81.0367 93.4717 90.076 83.5022 102.734C79.726 108.093 77.8378 114.136 77.8378 120.861C77.8378 128.533 79.8878 134.628 83.9878 139.147C88.0877 143.665 92.9969 146.976 98.7152 149.077C104.434 151.074 111.555 152.966 120.078 154.752C127.846 156.434 133.457 158.063 136.909 159.639C140.47 161.11 142.25 163.422 142.25 166.575C142.25 172.565 136.1 175.56 123.8 175.56C117.327 175.56 110.745 174.561 104.056 172.565C102.264 172.03 100.531 171.45 98.8554 170.825C91.9828 168.26 83.6862 170.654 80.6367 177.325L80.1202 178.455C77.6798 183.794 79.4301 190.201 84.8292 192.505C88.4306 194.042 92.4658 195.382 96.935 196.525C105.566 198.732 114.468 199.835 123.639 199.835C134.428 199.835 143.599 198.259 151.151 195.106C158.704 191.848 164.368 187.54 168.145 182.18C171.921 176.821 173.809 170.831 173.809 164.21C173.809 156.539 171.705 150.496 167.497 146.082C163.397 141.564 158.488 138.306 152.77 136.309C147.159 134.207 140.038 132.263 131.407 130.477C123.746 128.901 118.19 127.377 114.737 125.906C111.285 124.434 109.558 122.227 109.558 119.285C109.558 112.899 130.758 102.328 144.867 95.7087L143.048 103.09C141.456 109.552 145.404 116.082 151.866 117.674L153.708 118.128C160.17 119.72 166.699 115.772 168.292 109.309L176.921 74.2829C178.513 67.8206 174.565 61.2913 168.102 59.6992L166.261 59.2456C166.113 59.209 165.965 59.1754 165.816 59.1447L123.766 48.7851Z"
        fill="#F5F5FA" />
    </g>
    <defs>
      <filter
        id="filter0_bii_858_13162"
        x="-65.536"
        y="-65.536"
        width="387.072"
        height="387.072"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB">
        <feFlood
          flood-opacity="0"
          result="BackgroundImageFix" />
        <feGaussianBlur
          in="BackgroundImage"
          stdDeviation="32.768" />
        <feComposite
          in2="SourceAlpha"
          operator="in"
          result="effect1_backgroundBlur_858_13162" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_backgroundBlur_858_13162"
          result="shape" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha" />
        <feOffset
          dx="-17.0667"
          dy="17.0667" />
        <feGaussianBlur stdDeviation="8.53333" />
        <feComposite
          in2="hardAlpha"
          operator="arithmetic"
          k2="-1"
          k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.2 0" />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect2_innerShadow_858_13162" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha" />
        <feOffset
          dx="17.0667"
          dy="-17.0667" />
        <feGaussianBlur stdDeviation="8.53333" />
        <feComposite
          in2="hardAlpha"
          operator="arithmetic"
          k2="-1"
          k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.253333 0 0 0 0 0.253333 0 0 0 0 0.253333 0 0 0 0.2 0" />
        <feBlend
          mode="normal"
          in2="effect2_innerShadow_858_13162"
          result="effect3_innerShadow_858_13162" />
      </filter>
      <filter
        id="filter1_dd_858_13162"
        x="71.5704"
        y="44.2541"
        width="111.971"
        height="163.938"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB">
        <feFlood
          flood-opacity="0"
          result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha" />
        <feOffset dy="2.08917" />
        <feGaussianBlur stdDeviation="2.08917" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.06 0" />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_858_13162" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha" />
        <feOffset dy="2.08917" />
        <feGaussianBlur stdDeviation="3.13375" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
        <feBlend
          mode="normal"
          in2="effect1_dropShadow_858_13162"
          result="effect2_dropShadow_858_13162" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect2_dropShadow_858_13162"
          result="shape" />
      </filter>
      <radialGradient
        id="paint0_radial_858_13162"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(128 128) rotate(90) scale(128)">
        <stop
          stop-color="white"
          stop-opacity="0.44" />
        <stop
          offset="0.98116"
          stop-color="#77C4FC" />
        <stop
          offset="0.9999"
          stop-color="#6BACF9" />
      </radialGradient>
      <linearGradient
        id="paint1_linear_858_13162"
        x1="-73.6"
        y1="-48"
        x2="396.8"
        y2="380.8"
        gradientUnits="userSpaceOnUse">
        <stop
          offset="0.447328"
          stop-color="white"
          stop-opacity="0.711401" />
        <stop offset="0.664753" />
        <stop
          offset="1"
          stop-opacity="0" />
      </linearGradient>
      <radialGradient
        id="paint2_radial_858_13162"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(128 128) rotate(90) scale(128)">
        <stop
          stop-color="white"
          stop-opacity="0.44" />
        <stop
          offset="0.885417"
          stop-color="#4A9AFB" />
        <stop
          offset="0.9999"
          stop-color="#4A9AFB" />
      </radialGradient>
      <linearGradient
        id="paint3_linear_858_13162"
        x1="-73.6"
        y1="-48"
        x2="396.8"
        y2="380.8"
        gradientUnits="userSpaceOnUse">
        <stop
          offset="0.447328"
          stop-color="white"
          stop-opacity="0.711401" />
        <stop offset="0.664753" />
        <stop
          offset="1"
          stop-opacity="0" />
      </linearGradient>
    </defs>
  </svg>
</template>
