import type { EditableField } from '@/modules/utils/index.js'

import type { Team } from './teams.models.js'

export const TEAM_STORE_ID = 'teams'
export const ACTIVE_TEAM_STORE_ID = 'active-team'

export const TEAM_FIELDS: EditableField<Team>[] = [
  {
    field: 'name',
    label: 'team name',
    type: 'string',
    readonly: true,
  },
  {
    field: 'type',
    label: 'team type',
    type: 'string',
    readonly: true,
  },
  {
    field: 'id',
    label: 'team id',
    type: 'string',
    readonly: true,
  },
]

export const INVITE_FIELDS: EditableField<Team>[] = [
  {
    field: 'name',
    label: 'name',
    type: 'string',
    readonly: true,
  },
  {
    field: 'type',
    label: 'team type',
    type: 'string',
    readonly: true,
  },
]
