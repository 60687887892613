<script setup lang="ts">
import { computed } from 'vue'
import { useRoute } from 'vue-router'
import { storeToRefs } from 'pinia'

import WaitingListOverlay from '@/modules/auth/components/WaitingListOverlay.vue'
import { useAuthStore } from '@/modules/auth/index.js'
import { useActiveTeamStore, useTeamStore } from '@/modules/teams/index.js'

import { InvalidTeam, MenuNav } from '@/components/navigation/index.js'

// Config
const route = useRoute()
const authStore = useAuthStore()
const teamStore = useTeamStore()
const activeTeamStore = useActiveTeamStore()

// State
const { isBeta } = storeToRefs(authStore)
const { isReady, isAdmin } = storeToRefs(teamStore)
const { isActiveTeamValid, teamType } = storeToRefs(activeTeamStore)

// Getters
const isRouteAccessAllowed = computed(() => {
  if (isAdmin.value) return true
  const allowedTeamTypes = route.meta.accessControlledTeamTypes as string[]
  if (!(allowedTeamTypes && teamType.value)) return true
  return allowedTeamTypes.includes(teamType.value)
})
</script>

<template>
  <div class="h-screen w-screen items-center">
    <div class="relative z-[-999] h-full w-full">
      <div
        class="absolute left-1/2 top-1/2 h-1/2 w-1/2 bg-pink-600/10 mix-blend-multiply blur-[300px] filter" />

      <div
        class="absolute -top-[10%] left-1/4 h-1/2 w-1/2 bg-blue-500/30 mix-blend-multiply blur-[300px] filter" />

      <div
        class="absolute -left-[10%] top-1/2 h-1/2 w-1/2 bg-emerald-400/20 mix-blend-normal blur-[300px] filter" />
    </div>

    <div
      v-if="isReady"
      class="absolute left-0 top-0 z-10 flex h-full w-full max-w-full space-x-6 p-6">
      <MenuNav class="flex-0 z-20" />
      <main class="flex min-w-0 flex-1">
        <RouterView v-if="isActiveTeamValid && isRouteAccessAllowed" />
        <InvalidTeam v-else />
      </main>
      <WaitingListOverlay v-if="!isBeta" />
    </div>
  </div>
</template>
